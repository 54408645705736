<template>
  <div class="site">
    <canvas id="canvas"></canvas>
    <section class="site-content section p-5" >
      <section class="section pb-5 is-flex is-flex-direction-column is-justify-content-space-between">
        <app-logo />

        <!-- <div class="card">
          <div class="card-content has-text-centered">
            <p class="has-text-weight-bold pb-2">{{$t('system.duplicateConnection')}}</p>
            <p class="is-size-7">{{$t('system.onlyOneSCreen')}}</p>
          </div>
          <footer class="card-footer is-justify-content-center p-4">
            <b-button
            type="is-primary"
            size="is-small"
            :label="$t('system.useThisOne')"
            @click="Reload()"
            expanded/>
          </footer>
        </div> -->
        <div class="title"> {{ $t('system.debubMsj') }}</div>
        <div class="subtitle">{{$t('system.onlyOneSCreen')}}</div>
        <div style="margin: 0 auto">
          <b-button
            type="is-light"
            size="is-large"
            :label="$t('system.useThisOne')"
            @click="Reload()"
            />
        </div>


      </section>
    </section>
  </div>
</template>

<script scoped>
  export default {
    data() {
      return {
        ctx: null,
        noise: {
          scaleFactor: 2.5, // Noise size
          samples: [],
          sampleIndex: 0,
          scanOffsetY: 0,
          scanSize: 0,
          FPS: 50,
          SAMPLE_COUNT: 10,
        }
      };
    },
    methods: {
      Reload() {
        window.location.href="/spaces";
      },
      WhiteNoise(){
        this.ctx.putImageData(this.noise.samples[Math.floor(this.noise.sampleIndex)], 0, 0);

        this.noise.sampleIndex += 20 / this.noise.FPS; // 1/FPS == 1 second
        if(this.noise.sampleIndex >= this.noise.samples.length) this.noise.sampleIndex = 0;

        var grd = this.ctx.createLinearGradient(0, this.noise.scanOffsetY, 0, this.noise.scanSize + this.noise.scanOffsetY);

        this.ctx.fillStyle = grd;
        this.ctx.fillRect(0, this.noise.scanOffsetY, 1920, this.noise.scanSize + this.noise.scanOffsetY);
        this.ctx.globalCompositeOperation = "lighter";


        window.requestAnimationFrame(this.WhiteNoise);
      },
      interpolate(x, x0, y0, x1, y1) {
        return y0 + (y1 - y0)*((x - x0)/(x1 - x0));
      },
      generateRandomSample(context, w, h){
        var intensity = [];
        var factor = h / 50;
        var trans = 1 - Math.random() * 0.05;

        var intensityCurve = [];
        for(let i = 0; i < Math.floor(h / factor) + factor; i++)
          intensityCurve.push(Math.floor(Math.random() * 15));

        for(let i = 0; i < h; i++) {
          var value = this.interpolate((i/factor), Math.floor(i / factor), intensityCurve[Math.floor(i / factor)], Math.floor(i / factor) + 1, intensityCurve[Math.floor(i / factor) + 1]);
          intensity.push(value);
        }

        var imageData = context.createImageData(w, h);
        for(let i = 0; i < (w * h); i++) {
          var k = i * 4;
          var color = Math.floor(36 * Math.random());
          // Optional: add an intensity curve to try to simulate scan lines
          color += intensity[Math.floor(i / w)];
          imageData.data[k] = imageData.data[k + 1] = imageData.data[k + 2] = color;
          imageData.data[k + 3] = Math.round(255 * trans);
        }
        return imageData;
      }
    },
    mounted(){
      this.$store.commit("StopLoading")
      const canvas = document.getElementById("canvas");
      if (canvas.getContext) {
        this.ctx = canvas.getContext("gl") || canvas.getContext("2d"),
        
        canvas.width = 1920 // / this.noise.scaleFactor;
        canvas.height = 1080 // / (canvas.offsetWidth / canvas.offsetHeight);
        this.noise.scanSize = (canvas.offsetHeight / this.noise.scaleFactor) / 3;

        this.noise.samples = []
        for(let i = 0; i < this.noise.SAMPLE_COUNT; i++)
         this.noise.samples.push(this.generateRandomSample(this.ctx, canvas.width, canvas.height));
        
        this.WhiteNoise()
      }
    }
  };
</script>
  

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.route_duplicate {
  background-color: $system-screen-background;

  .site-content > section {

    height: 100%

  }

  .card {
    @include blurBox();
  }
}

#canvas {
	position: absolute;
	top: 0;
	left: 0;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	-webkit-perspective: 1000;
  z-index: -1;
}
.title{
  color: #fff;
  font-size: 200px;
  text-align: center;
  margin-top: 100px;
  opacity: .5;
}
.subtitle{
  color: #fff;
  font-size: 30px;
  text-align: center;
  margin-top: 100px !important;
  opacity: .5;
}
</style>